import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M3.59998 20.4C3.59998 20.0818 3.7264 19.7765 3.95145 19.5515C4.17649 19.3265 4.48172 19.2 4.79998 19.2H19.2C19.5182 19.2 19.8235 19.3265 20.0485 19.5515C20.2735 19.7765 20.4 20.0818 20.4 20.4C20.4 20.7183 20.2735 21.0235 20.0485 21.2486C19.8235 21.4736 19.5182 21.6 19.2 21.6H4.79998C4.48172 21.6 4.17649 21.4736 3.95145 21.2486C3.7264 21.0235 3.59998 20.7183 3.59998 20.4ZM7.55158 11.1516C7.77661 10.9267 8.08178 10.8003 8.39998 10.8003C8.71817 10.8003 9.02334 10.9267 9.24837 11.1516L10.8 12.7032V3.60002C10.8 3.28176 10.9264 2.97654 11.1514 2.7515C11.3765 2.52645 11.6817 2.40002 12 2.40002C12.3182 2.40002 12.6235 2.52645 12.8485 2.7515C13.0735 2.97654 13.2 3.28176 13.2 3.60002V12.7032L14.7516 11.1516C14.8623 11.037 14.9947 10.9456 15.1411 10.8827C15.2875 10.8198 15.445 10.7867 15.6043 10.7853C15.7636 10.7839 15.9216 10.8143 16.0691 10.8746C16.2166 10.935 16.3506 11.0241 16.4632 11.1368C16.5759 11.2494 16.665 11.3834 16.7254 11.5309C16.7857 11.6784 16.8161 11.8364 16.8147 11.9957C16.8133 12.155 16.7802 12.3125 16.7173 12.4589C16.6544 12.6053 16.563 12.7377 16.4484 12.8484L12.8484 16.4484C12.6233 16.6734 12.3182 16.7998 12 16.7998C11.6818 16.7998 11.3766 16.6734 11.1516 16.4484L7.55158 12.8484C7.32661 12.6234 7.20023 12.3182 7.20023 12C7.20023 11.6818 7.32661 11.3767 7.55158 11.1516V11.1516Z",
      "clip-rule": "evenodd"
    })
  ]))
}