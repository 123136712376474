import { formatLength } from '@ankor-io/common/vessel/Formatter'
import { Amenity, Blueprint, CrewMember, Toy, Vessel } from '@ankor-io/common/vessel/types'
// the following imports are absolute by design
import { Crew, VesselCrewData } from '@ankor-io/wheelhouse/src/sections/vessel/crew/types'
import { VesselHeroData } from '@ankor-io/wheelhouse/src/sections/vessel/hero/types'
import { VesselOverviewData } from '@ankor-io/wheelhouse/src/sections/vessel/overview/types'
import { KeyValueSpecification, VesselSpecificationsSectionData } from '@ankor-io/wheelhouse/src/sections/vessel/specifications/types/types'
import { ToysAmenitiesSectionData } from '@ankor-io/wheelhouse/src/sections/vessel/toys-amenities/types/types'

export const vesselHeroLiteSectionMapper = {
  from: (source: Vessel): VesselHeroData => {
    return {
      name: source.blueprint?.name || '',
      type: source.yachtType?.join(', ') || '',
      length: formatLength(source.blueprint?.length || 0),
      sleeps: (source.blueprint?.sleeps || 0).toString(),
      cabins: (source.blueprint?.cabins || 0).toString(),
      crew: (source.blueprint?.maxCrew || 0).toString(),
      builtYear: (source.blueprint?.builtYear || 0).toString(),
      images: source.blueprint?.images || [],
      model: source.blueprint?.model || '',
      builder: source.blueprint?.make || '',
    }
  },
}

export const vesselOverviewLiteSectionMapper = {
  from: (source: Vessel): VesselOverviewData => {
    return {
      heading: 'Overview',
      description: source?.description || '',
    }
  },
}

export const vesselSpecificationsLiteSectionMapper = {
  from: (source: Vessel): VesselSpecificationsSectionData => {
    const blueprint: Blueprint = source.blueprint
    const specifications: KeyValueSpecification[] = [
      {
        key: 'Yacht Category',
        value: source.yachtType?.join(', ') || '',
      },
      {
        key: 'Builder',
        value: blueprint.make || '',
      },
      {
        key: 'Model',
        value: blueprint.model || '',
      },
      {
        key: 'Max Crew',
        value: blueprint.maxCrew?.toString() || '',
      },
      {
        key: 'Sleeps',
        value: blueprint.sleeps?.toString() || '',
      },
      {
        key: 'Build Year',
        value: blueprint.builtYear?.toString() || '',
      },
      {
        key: 'Architect',
        value: blueprint.architect || '',
      },
      {
        key: 'Interior Designer',
        value: blueprint.interiorDesigner || '',
      },
      {
        key: 'Refit Year',
        value: blueprint.refitYear?.toString() || '',
      },
      {
        key: 'License',
        value: blueprint.flag || '',
      },
      {
        key: 'Registration',
        value: blueprint.registryPort || '',
      },
      {
        key: 'Top Speed (Kt/hr)',
        value: blueprint.topSpeed?.toString() || '',
      },
      {
        key: 'Cruising Speed (Kt/hr)',
        value: blueprint.cruiseSpeed?.toString() || '',
      },
      {
        key: 'Fuel Capacity (L)',
        value: blueprint.fuelCapacity?.toString() || '',
      },
      {
        key: 'Engines',
        value: blueprint.engines || '',
      },
      {
        key: 'Cabins',
        value: blueprint.cabins?.toString() || '',
      },
      {
        key: 'Bathrooms',
        value: blueprint.bathrooms?.toString() || '',
      },
      {
        key: 'Decks',
        value: blueprint.decks?.toString() || '',
      },
      {
        key: 'Length',
        value: formatLength(blueprint?.length.toString() || 0),
      },
      {
        key: 'Beam Length',
        value: formatLength(blueprint?.beam?.toString() || 0),
      },
      {
        key: 'Draft',
        value: blueprint.draft?.toString() || '',
      },
      {
        key: 'Hull Type',
        value: blueprint.hullType || '',
      },
      {
        key: 'Hull Construction',
        value: blueprint.hullConstruction || '',
      },
      {
        key: 'Tonnage (T)',
        value: blueprint.tonnage?.toString() || '',
      },
      {
        key: 'Super Structure',
        value: blueprint.superStructure || '',
      },
      {
        key: 'Mainsail Types',
        value: blueprint.mainsailTypes || '',
      },
      {
        key: 'Genoa Types',
        value: blueprint.genoaTypes || '',
      },
    ]

    return {
      specifications,
    }
  },
}

export const vesselCrewLiteSectionMapper = {
  from: (source: Vessel): VesselCrewData => {
    const crewMembers: Crew[] = (source.crew || []).map((crewMember: CrewMember) => {
      return {
        selected: true,
        name: crewMember.name,
        avatar: crewMember.avatar,
        bio: crewMember.bio,
        roles: crewMember.roles,
        email: crewMember.email,
        phone: crewMember.phone,
      }
    })
    return {
      heading: 'Crew',
      crewMembers,
    }
  },
}

export const vesselToysAmenitiesLiteSectionMapper = {
  from: (source: Vessel): ToysAmenitiesSectionData => {
    const blueprint = source.blueprint || {}
    return {
      heading: 'Toys & Amenities',
      toys: (blueprint.toys || []).map(
        (toy: Toy) => `${toy.label}${toy.quantity && toy.quantity > 1 ? ` x ${toy.quantity}` : ''}`,
      ),
      amenities: (blueprint.amenities || []).map(
        (amenity: Amenity) =>
          `${amenity.label}${amenity.quantity && amenity.quantity > 1 ? ` x ${amenity.quantity}` : ''}`,
      ),
      entertainment: blueprint.entertainment || [],
      tenders: blueprint.tenders || [],
    }
  },
}
