import gql from 'graphql-tag'
import { Component } from 'vue'

import { Blueprint } from '@ankor-io/common/vessel/types'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'
import { KeyValueSpecification, VesselSpecificationsSectionData } from './types/types'

import ConfigurationComponent from './VesselSpecificationsConfiguration.vue'
import VesselSpecificationsSectionEditorVue from './VesselSpecificationsSectionEditor.vue'

const QUERY = gql`
  query Vessel($URI: String!) {
    vessel(uri: $URI) {
      type
      blueprint {
        mainsailTypes
        genoaTypes
        length
        flag
        registryPort
        cabins
        sleeps
        make
        model
        bathrooms
        builtYear
        decks
        architect
        interiorDesigner
        maxCrew
        beam
        draft
        hullType
        hullConstruction
        superStructure
        tonnage
        engines
        fuelCapacity
        topSpeed
        cruiseSpeed
        refitYear
      }
    }
  }
`

export class VesselSpecificationsSectionEditor extends ProposalAbstractEditableSection<
  VesselSpecificationsSectionData,
  EditableProposal
> {
  async fetchData(): Promise<VesselSpecificationsSectionData> {
    const client = await this.client()

    const { data, errors } = await client.query({
      query: QUERY,
      variables: { URI: this.getSlideUri() },
      errorPolicy: 'all',
    })

    console.debug('VesselSpecificationsSectionEditor errors: ', errors)

    const blueprint: Blueprint = data.vessel.blueprint
    const specifications: KeyValueSpecification[] = [
      {
        key: 'Yacht Category',
        value: data.vessel.yachtType?.join(', '),
      },
      {
        key: 'Builder',
        value: blueprint.make,
      },
      {
        key: 'Model',
        value: blueprint.model,
      },
      {
        key: 'Max Crew',
        value: blueprint.maxCrew?.toString(),
      },
      {
        key: 'Sleeps',
        value: blueprint.sleeps?.toString(),
      },
      {
        key: 'Build Year',
        value: blueprint.builtYear?.toString(),
      },
      {
        key: 'Architect',
        value: blueprint.architect,
      },
      {
        key: 'Interior Designer',
        value: blueprint.interiorDesigner,
      },
      {
        key: 'Refit Year',
        value: blueprint.refitYear?.toString(),
      },
      {
        key: 'License',
        value: blueprint.flag,
      },
      {
        key: 'Registration',
        value: blueprint.registryPort,
      },
      {
        key: 'Top Speed (Kt/hr)',
        value: blueprint.topSpeed?.toString(),
      },
      {
        key: 'Cruising Speed (Kt/hr)',
        value: blueprint.cruiseSpeed?.toString(),
      },
      {
        key: 'Fuel Capacity (L)',
        value: blueprint.fuelCapacity?.toString(),
      },
      {
        key: 'Engines',
        value: blueprint.engines,
      },
      // {
      //   key: 'Cabin Layout',
      //   value: blueprint.cabinLayout,
      // },
      {
        key: 'Cabins',
        value: blueprint.cabins?.toString(),
      },
      {
        key: 'Bathrooms',
        value: blueprint.bathrooms?.toString(),
      },
      {
        key: 'Decks',
        value: blueprint.decks?.toString(),
      },
      {
        key: 'Length',
        value: blueprint.length.toString(),
      },
      {
        key: 'Beam Length',
        value: blueprint.beam?.toString(),
      },
      {
        key: 'Draft',
        value: blueprint.draft?.toString(),
      },
      {
        key: 'Hull Type',
        value: blueprint.hullType,
      },
      {
        key: 'Hull Construction',
        value: blueprint.hullConstruction,
      },
      {
        key: 'Tonnage (T)',
        value: blueprint.tonnage?.toString(),
      },
      {
        key: 'Super Structure',
        value: blueprint.superStructure,
      },
      {
        key: 'Mainsail Types',
        value: blueprint.mainsailTypes,
      },
      {
        key: 'Genoa Types',
        value: blueprint.genoaTypes,
      },
    ]

    const vesselData: VesselSpecificationsSectionData = {
      specifications,
    }

    return Promise.resolve(vesselData)
  }

  getType(): SectionType {
    return SectionType.VESSEL_SPECIFICATIONS
  }

  getComponent(): Component {
    return VesselSpecificationsSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return ConfigurationComponent
  }
}
