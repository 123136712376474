<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import Loading from '@/components/Spinner.vue'

import '@ankor-io/common/tailwindcss/base.scss'

const AppContext = defineAsyncComponent(() => import('./AppContext.vue'))
</script>
<template>
  <Suspense>
    <template #fallback>
      <Loading class="h-screen" />
    </template>
    <AppContext>
      <main class="page-main bg-white dark:bg-gray-900">
        <RouterView name="main"/>
      </main>
    </AppContext>
  </Suspense>
</template>
<style>
</style>