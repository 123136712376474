<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import { Ref, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import ImageCarouselModal from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModal.vue'
import { JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { OutlinePhoto } from '@ankor-io/icons/outline'
import { OutlineChevronDown } from '@ankor-io/icons/outline'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import Pricing from '@/sections/vessel-showcase/Pricing.vue'
import {
  Alignment,
  Size,
  VesselShowcaseLayoutTemplate,
  VesselShowcaseSectionData,
} from '@/sections/vessel-showcase/types/types'

type Props = {
  uri: string
  proposalUri: string
  data: VesselShowcaseSectionData
  layout: VesselShowcaseLayoutTemplate
  document: JsonProposal
}

const props = defineProps<Props>()
const showAllFeatures: Ref<boolean> = ref(false)
const showCarouselModal: Ref<boolean> = ref(false)

const getWrapperClasses = (size: Size = 'large', alignment: Alignment = 'center'): string => {
  let sizeClass = 'sm:w-3/4'
  if (size === 'small') {
    sizeClass = 'sm:w-1/2'
  }
  if (size === 'large') {
    sizeClass = 'w-full'
  }
  return twMerge('antialiased px-4 sm:p-8 mx-auto', sizeClass, `text-${alignment}`)
}

const featuredItems = props.data.features.filter((feature) => feature.featured)
</script>
<template>
  <section :class="getWrapperClasses(props.layout.options?.size, props.layout.options?.alignment)">
    <!-- Vessel name -->
    <h1 class="text-3xl sm:text-6xl">
      <SingleLineTextEditor :value="props.data.name" placeholder="Vessel name" :is-editable="false" />
    </h1>

    <!-- Vessel type -->
    <h3 v-if="props.data.type">
      <SingleLineTextEditor :value="props.data.type" placeholder="Vessel Type" :is-editable="false" />
    </h3>

    <div
      class="flex"
      :class="[
        { 'justify-center': props.layout.options?.alignment === 'center' },
        { 'justify-end': props.layout.options?.alignment === 'right' },
      ]"
    >
      <hr class="border-t-2 my-1 border-theme-primary w-28" />
    </div>

    <!-- Featured Items -->
    <div v-if="featuredItems.length" class="relative z-10 ml-3 mr-3 mt-3 select-none">
      <dl
        class="inline-flex flex-wrap justify-center px-2 @sm:px-4 py-1 @sm:py-2 shadow-lg gap-2 @sm:gap-8 rounded-lg"
        :class="{ border: props.layout.options?.showHighlightsBorder }"
        :style="{ background: props.layout.options?.highlightsBackground }"
      >
        <div
          v-for="(featured, featuredIndex) of featuredItems"
          :key="`${featured.key}-${featuredIndex}`"
          class="flex flex-col justify-center items-center"
        >
          <dd class="text-xs @sm:text-sm font-normal opacity-60">
            <h3><SingleLineTextEditor :value="featured.key" :is-editable="false" /></h3>
          </dd>
          <dt class="-mt-1 text-sm @sm:text-2xl font-extrabold">
            <h3><SingleLineTextEditor :value="featured.value" :is-editable="false" /></h3>
          </dt>
        </div>
      </dl>
    </div>

    <!-- Vessel Gallery -->
    <div class="relative -mt-6">
      <div class="h-[22.5rem] sm:h-full">
        <AssetViewer
          v-if="props.data.images?.[0]"
          class="w-full overflow-hidden rounded-2xl col-span-2 object-cover"
          :url="`/media/${props.data.images[0]}`"
        />
      </div>

      <!-- Show all button -->
      <button
        v-if="props.data.images?.length"
        type="button"
        class="absolute bottom-8 right-8 sm:bottom-8 sm:right-8 z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
        @click="showCarouselModal = true"
      >
        <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
        Show all photos
      </button>
    </div>

    <!-- Features -->
    <section v-if="props.layout.options?.showFeatures">
      <div
        class="grid gap-x-4 gap-y-4 pt-2 mt-4 grid-cols-2 sm:grid-cols-5 text-left transition-all duration-500 overflow-y-auto"
        :class="[showAllFeatures ? 'max-h-[25rem] ease-in' : 'max-h-36 ease-out']"
      >
        <div
          v-for="(feature, featureIndex) in props.data.features"
          class="relative rounded-lg"
          :key="`${featureIndex}${feature.key}`"
        >
          <div
            class="h-full rounded-md border-theme-primary p-2 gap-x-2 gap-y-1 shadow-theme-shading text-center"
            :style="{ background: props.layout.options?.featuresBackground }"
          >
            <h2 class="mb-1 text-sm cursor-text opacity-6">
              <SingleLineTextEditor placeholder="Feature" :value="feature.key" :is-editable="false" />
            </h2>
            <div class="text-lg cursor-text">
              <SingleLineTextEditor placeholder="Feature value" :value="feature.value" :is-editable="false" />
            </div>
          </div>
        </div>
      </div>

      <!-- shading -->
      <div
        class="hidden crossfade-gradient pointer-events-none h-8 -mt-8 relative z-10"
        :class="showAllFeatures ? 'opacity-0' : 'opacity-100'"
      ></div>

      <!-- Feature actions -->
      <div
        class="flex items-center justify-end gap-x-1 mt-2 text-sm cursor-pointer"
        @click="showAllFeatures = !showAllFeatures"
      >
        Show
        <span>{{ showAllFeatures ? 'less' : 'more' }}</span>
        <OutlineChevronDown
          class="w-4 h-4 transition-all duration-700"
          :class="showAllFeatures ? 'rotate-180' : 'rotate-0'"
        />
      </div>
    </section>

    <!-- Vessel description -->
    <p v-if="props.layout.options?.showDescription" class="mt-5">
      <MultiLineTextEditor :value="props.data?.description || ''" :is-editable="false" />
    </p>

    <!-- button-->
    <button
      v-if="props.layout.options?.showButton"
      class="text-white font-medium text-sm p-4 mt-4 rounded-lg max-w-full"
      :class="!props.data.buttonLink ? 'bg-gray-200 bg-opacity-25 shadow' : 'bg-theme-primary'"
    >
      <a target="_blank" :href="props.data.buttonLink || ''">
        {{ props.data.buttonText || 'Yacht Brochure' }}
      </a>
    </button>

    <!-- Price Offerings -->
    <Pricing
      v-if="props.data.pricing && props.layout.options?.showPricing"
      :pricing="props.data.pricing"
      :layoutOptions="props.layout.options"
    />
  </section>

  <Teleport v-if="showCarouselModal" to="body">
    <ImageCarouselModal :slides="props.data.images" @close="showCarouselModal = false" />
  </Teleport>
</template>

<style scoped>
.crossfade-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 15.04%,
    v-bind('props.document.template.theme.color.background') 82.09%
  );
}
</style>
