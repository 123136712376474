<script setup lang="ts">
import { Ref, ref, watch, onMounted } from 'vue'
import { SolidChevronDown } from '@ankor-io/icons/solid'

type Props = {
  id: string
  value: string
  options: string[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'select:value', value: string): void
}>()

const isOpen: Ref<boolean> = ref(false)
const selectedValue: Ref<string | null> = ref(null)

const select = (value: string): void => {
  emit('select:value', value)
}

onMounted(() => {
  selectedValue.value = props.value
})

watch(
  () => props.value,
  (newValue: string) => {
    selectedValue.value = newValue
  },
)
</script>

<template>
  <button
    type="button"
    data-dropdown-toggle="dropdown"
    class="w-full h-full text-gray-500 bg-gray-50 border border-gray-300 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-3 text-center inline-flex items-center justify-between"
    :id="`${props.id}Button`"
    @blur="isOpen = false"
    @click="isOpen = !isOpen"
  >
    <span>{{ selectedValue }}</span>
    <SolidChevronDown class="w-2.5 fill-gray-500" />
  </button>
  <!-- Dropdown menu -->
  <div
    v-show="isOpen"
    class="dropdown absolute w-full mt-2 p-4 z-10 bg-white border border-gray-200 divide-y divide-gray-100 rounded-lg shadow"
    :id="`${props.id}Options`"
  >
    <ul class="flex flex-col gap-y-3 text-sm text-gray-700" :aria-labelledby="`${props.id}Button`">
      <li
        v-for="(option, index) of props.options"
        class="cursor-pointer hover:text-primary-600"
        :key="index"
        :class="{ 'text-primary-600': option === selectedValue }"
        @mousedown="select(option)"
      >
        <span>{{ option }}</span>
      </li>
    </ul>
  </div>
</template>
