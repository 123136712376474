import { ItineraryIndexItem } from '@ankor-io/common/index/ItineraryIndexItem'
import { ProposalIndexItem } from '@ankor-io/common/index/ProposalIndexItem'
import { Serializable } from '@ankor-io/common/lang/Serializable'
import { SectionTemplate } from '@ankor-io/common/proposal/Section'

import { VesselIndexItem } from '../index/VesselIndexItem'
import { YachtIndexItem } from '../index/YachtIndexItem'
import { Document as DocumentInterface, EditableDocument as EditableDocumentInterface, JsonDocument } from './Document'
import { SlideTemplate } from './Slide'

/**
 * Static Template Object structure
 */
export type Template = {
  /**
   * theme properties
   * && yarn workspace @ankor-io/hatch ci:build && BRANCH_NAME=$BRANCH_NAME yarn workspace @ankor-io/hatch ci:provision
   */
  theme: ThemeTemplate
  /**
   * header static template
   */
  header: HeaderTemplate
  /**
   * footer static template
   */
  footer: FooterTemplate
  /**
   * yacht slide static template
   */
  yacht: SlideTemplate
  /**
   * vessel slide static template
   */
  vessel: SlideTemplate
  /**
   * itinerary slide static template
   */
  itinerary: SlideTemplate
  /**
   * The identity of the user profile and company
   */
  identity: Identity
}

/**
 * Contains the details of a users profile and the broker company
 */
export type Identity = {
  // profile
  givenName: string
  surname: string
  image: string
  role: string
  email: string
  phone: string
  website: string
  // company
  companyImage: string
  companyName: string
  companyAddress: string
  companyPhone: string
}

/**
 * Definition of a font item
 */
export type FontItem = {
  family: string
  variants: string[]
  subsets: string[]
  version: string
  lastModified: string
  files: {
    regular: string
  }
  category: string
  kind: string
  css?: string
}

/**
 * Theme properties
 */
export type ThemeTemplate = {
  color: {
    primary: string
    background: string
    shading: string
    accent: string
  }
  font: {
    heading: {
      type: FontItem
      color: string
    }
    subheading: {
      type: FontItem
      color: string
    }
    primary: {
      type: FontItem
      color: string
    }
  }
  density: string
  borders: string
}

/**
 * Header static template structure
 */
export type HeaderTemplate = {
  section: SectionTemplate
}

/**
 * Footer static template structure
 */
export type FooterTemplate = {
  section: SectionTemplate
}

/**
 * Structure of a proposal object
 */
export type JsonProposal = {
  uri: string
  template: Template
  document: JsonDocument
  proposalItems: ProposalItem[]
  flow: 'NEEDS_INIT' | 'INITIALIZED'
  indexable: boolean
  tags: string[]
  internalName: string
  /**
   * @deprecated The proposal client facing name - this is deprecated and removed from the UI, but still exists in the back-end
   */
  externalName?: string
}

/**
 * Structure of a proposal object
 */
export interface Proposal extends Serializable<JsonProposal> {
  /**
   * The proposal uri
   */
  readonly uri: string
  /**
   * the proposal static template
   */
  readonly template: Template
  /**
   * the proposal document
   */
  readonly document: DocumentInterface
  /**
   * the list of proposal items
   */
  readonly proposalItems: ProposalItem[]
  /**
   * the internal name of proposal
   */
  readonly internalName: string
  /**
   * @deprecated The external name of the proposal - this is deprecated and removed from the UI, but still exists in the back-end
   */
  readonly externalName?: string
  /**
   * the flow of proposal
   */
  readonly flow: 'NEEDS_INIT' | 'INITIALIZED'
  /**
   * the flag to determine if proposal is indexable
   */
  readonly indexable: boolean
  /**
   * the list of tags
   */
  readonly tags: string[]
}

export interface EditableProposal extends Serializable<JsonProposal> {
  /**
   * The proposal uri
   */
  readonly uri: string
  /**
   * the proposal static template
   */
  readonly template: Template
  /**
   * the proposal document
   */
  readonly document: EditableDocumentInterface
  /**
   * the list of proposal items
   */
  readonly proposalItems: ProposalItem[]
  /**
   * the internal name of proposal
   */
  readonly internalName: string
  /**
   * @deprecated The external name of the proposal - this is deprecated and removed from the UI, but still exists in the back-end
   */
  readonly externalName?: string
  /**
   * the flow of proposal
   */
  readonly flow: 'NEEDS_INIT' | 'INITIALIZED'
  /**
   * the flag to determine if proposal is indexable
   */
  readonly indexable: boolean
  /**
   * the list of tags
   */
  readonly tags: string[]
  /**
   * Returns whether the proposal is in initialization state or not.
   * This is dictated by the sections, when all the sections have completed
   * hydration, the proposal is considered initialized
   */
  isInitializing(): boolean
  /**
   * Performs all the necessary cleanups before this proposal is
   * reassigned to a new value
   */
  destroy(): void
}

/**
 * Represent a type of Item that can be added to a proposal
 */
export type ProposalItem = {
  /**
   * the proposal item type
   */
  type: ProposalItemType.ITINERARY | ProposalItemType.PROPOSAL | ProposalItemType.YACHT | ProposalItemType.VESSEL
  /**
   * The full uri for this proposal item
   */
  uri: string
  /**
   * one of the indexed item
   * ItineraryIndexItem | ProposalIndexItem | YachtIndexItem | VesselIndexItem
   */
  item: ItineraryIndexItem | ProposalIndexItem | YachtIndexItem | VesselIndexItem | null
}

/**
 * Cart document object structure
 */
export type CartDocument = {
  /**
   * uri of proposal in cart
   * @deprecated not required to store the uri in the cart document
   */
  proposalUri: string
  /**
   * internal name of proposal in cart
   */
  proposalInternalName?: string
  /**
   * flag to determine
   * if proposal document needs to be created
   * or is already created,
   * enables loading of same proposal document
   * without generating a new proposal uri
   * everytime the cart is mounted
   * @deprecated not required to keep a state of the cart document
   */
  createDoc: boolean
  /**
   * uri of the proposal that has been dropped / added in the cart
   * enables us to provide invalid state of the cart
   * in order to restrict user from dropping multiple proposals
   */
  droppedProposalUri: string
  /**
   * The proposal items in the cart
   */
  proposalItems: ProposalItem[]
  /**
   * The proposal template
   */
  proposalTemplate?: Template
}

/**
 * Available Proposal Item types
 */
export enum ProposalItemType {
  YACHT = 'yacht',
  VESSEL = 'vessel',
  PROPOSAL = 'proposal',
  ITINERARY = 'itinerary',
}
