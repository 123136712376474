import { createRouter, createWebHistory } from 'vue-router'

import TripsDashboard from '@/app/trips/Dashboard.vue'
import LibraryPresentations from '@/app/trips/library/Presentations.vue'
import LibraryRoutes from '@/app/trips/library/Routes.vue'
import LibraryYachts from '@/app/trips/library/Yachts.vue'

const routerV2 = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/trips/library',
      name: 'trips-library',
      components: {
        main: TripsDashboard,
      },
      children: [
        {
          path: 'presentations',
          name: 'trips-library-presentations',
          components: {
            content: LibraryPresentations,
          },
        },
        {
          path: 'routes',
          name: 'trips-library-routes',
          components: {
            content: LibraryRoutes,
          },
        },
        {
          path: 'yachts',
          name: 'trips-library-yachts',
          components: {
            content: LibraryYachts,
          },
        },
      ],
    },
  ],
  scrollBehavior(_to, _from, _savedPosition) {
    return {
      // always scroll to top on any route change.
      top: 0,
      behavior: 'smooth',
    }
  },
})

export default routerV2
