import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", { d: "M16.3032 4.30319C16.5246 4.07396 16.7894 3.89113 17.0822 3.76534C17.375 3.63956 17.6899 3.57336 18.0086 3.57059C18.3273 3.56782 18.6433 3.62854 18.9383 3.74922C19.2332 3.86989 19.5012 4.0481 19.7265 4.27344C19.9519 4.49878 20.1301 4.76675 20.2507 5.0617C20.3714 5.35665 20.4321 5.67268 20.4294 5.99135C20.4266 6.31002 20.3604 6.62495 20.2346 6.91776C20.1088 7.21057 19.926 7.47539 19.6968 7.69679L18.7452 8.64839L15.3516 5.25479L16.3032 4.30319V4.30319ZM13.6548 6.95159L3.59998 17.0064V20.4H6.99358L17.0496 10.3452L13.6536 6.95159H13.6548Z" })
  ]))
}