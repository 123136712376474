import { EditInit } from './lib/edit'
import { EditStack } from './lib/edit.stack'

export enum SavedStatus {
  IS_SAVED = 'is_saved',
  SAVING = 'is_saving',
  UNSAVED = 'unsaved',
}

export interface Message {
  /**
   * The diff sync unique reference
   */
  ref: string
  /**
   * The stack of edits
   */
  editStack: EditStack
  /**
   * When the server ack
   */
  isSaved?: SavedStatus.IS_SAVED | SavedStatus.SAVING
}

/**
 * Represents the hibernable structure of a Differential Synchronization
 */
export declare type DiffSyncState = {
  ref: string
  /**
   * The shadow document state
   */
  shadow: ShadowState,
  /**
   * The backup document state
   */
  backup: BackupState,
  /**
   * The outgoing edit stack state
   */
  outgoingEditStack: {
    edits: EditInit[]
  }
}

export declare type ShadowState = {
  localRev: number
  remoteRev: number
  content: any
}

export declare type BackupState = {
  localRev: number
  content: any
}
